@import "../../style.module.sass";

.plusButton {
  border-radius: 40px;
  background: #fff;
  padding: 5px;
  height: 26px;
  min-width: 26px;
  box-shadow: 0 1px 14px 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 15px;
    position: relative; } }

.plusButton.small {
  border-radius: 20px;
  background: #fff;
  padding: 5px;
  height: 20px;
  min-width: 20px;

  img {
    width: 15px;
    position: relative; } }

.plusPrimaryGreyButton {
  border-radius: 40px;
  background: $lightGrey;
  padding: 5px;
  height: 26px;
  min-width: 26px;

  img {
    width: 14px;
    position: relative;
    top: 6px;
    left: 6px; } }


.plusPrimaryPinkButton {
  border-radius: 40px;
  background: $primaryPink;
  padding: 5px;
  height: 26px;
  min-width: 26px;

  img {
    width: 20px;
    position: relative;
    top: 3px;
    left: 3px; } }


.primaryButton {
  background: $primaryPink;
  border-radius: 30px;
  color: $white;
  width: 100%;
  padding: 15px 10px;
  text-align: center;
  box-sizing: border-box;
  font-weight: 500; }

.primaryButton.disabled {
  background: #d7d7d7;
  color: $textGrey; }
