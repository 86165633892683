
.header {
  margin: 0 auto;
  max-width: 900px;
  position: relative;
  z-index: 50;

  .nameAndDescription {
    position: absolute;
    left: 9%;
    right: 0;
    bottom: -40px;
    z-index: 10;
    padding: 0 10px 15px 10px;
    background: white;
    width: 80%;
    border-radius: 10px; }



  .backgroundImage {
    margin: 0;

    img {
      object-fit: cover;
      max-width: 100%;
      min-width: 100%;
      height: 250px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; } }

  .title {
    font-size: 30px;
    font-weight: bold;
    margin: 10px 20px 0; }


  .description {
    font-size: 16px;
    color: #A2A2A2;
    margin: 0 20px 10px;

    a {
      text-decoration: none;
      color: #1a1a1a;
      font-weight: 500; } } }


@media (max-width: 900px) {

  .header {
    .nameAndDescription {
      width: 96%;
      left: 1%; } } }


