@import "../../../style.module.sass";


.backButtonContainer {
  position: absolute;
  padding: 10px;
  z-index: 5; }

.backButton {
  .img {
    max-width: 10px; }

  .title {
    color: $white;
    font-size: 16px;

    position: relative;
    top: -4px;
    left: 8px; } }
