@import "../../../../style.module.sass";

.overlay {
  background-color: #fff;
  backdrop-filter: blur(2px);
  position: fixed;
  top: 100vh;
  z-index: 800; }

.overlay.open {
  background-color: rgba(144, 144, 144, 0.6);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0; }



