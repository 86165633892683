@import "../../../../style.module.sass";
@import "../menuItems/style.module.sass";
@import "../header/style.module.sass";

.categoriesContainer {
  position: sticky;
  z-index: 20;
  margin: 5px 0 0;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow-y: auto;
  -ms-overflow-style: none /* IE and Edge */;
  scrollbar-width: none;
  top: 0;
  background: white;
  font-size: 15px;
  padding: 5px 100px 5px 60px;


  .item {
    padding: 10px 20px;
    color: #8a8a8a;
    font-weight: 500;
    margin: 0 10px;
    z-index: 20;
    white-space: nowrap; }

  .item.active {
    color: $white; }

  .item.mr20 {
    margin-right: 20px; }

  .hoverElement {
    position: absolute;
    top: 4px;
    left: 4px; // This value will be updated by Framer Motion
    width: 0; // This value will be updated by Framer Motion
    height: 40px;
    border-radius: 90px;
    background-color: #1a1a1a;
    z-index: 1; } }




.categoriesContainer::-webkit-scrollbar {
  display: none; }


.burger {
  position: absolute;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 21;
  padding: 8px 8px 8px 16px;
  background-color: white; }

.burger span {
  height: 4px;
  background: black;
  display: block;
  border-radius: 5px; }

.burger span:before {
  content: "";
  top: -12px; }
.burger span {
  top: 22px; }

.burger span:after {
  content: "";
  bottom: -12px; }


.burgerCategories {
  position: sticky;
  top: 0;
  z-index: 61; }

.burgerMenu {
  position: fixed;
  top: 500px;
  left: 10px;
  right: 10px;
  transition: top 0.3s cubic-bezier(0,1.09,.48,1.0),  left 0.3s cubic-bezier(.26,1.14,.12,.94),  right 0.3s cubic-bezier(.26,1.14,.12,.94), opacity 0.2s;
  //opacity: 0
  display: none;
  max-width: 500px;
  background-color: white;
  box-sizing: border-box;

  border-radius: 20px 20px 0 0;
  padding: 5px 17px 120px 17px;
  margin: auto;



  h3 {
    font-size: 24px; }

  .burgerMenuCategoriesContainer {
    max-height: calc(100vh - 200px);
    min-height: calc(100vh - 200px);
    overflow-y: scroll;
    background: white; }

  .category {
    display: flex;
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin-bottom: 15px;
    background: white;
    position: relative; }

  .imageCategory {
    width: 30%;
    img {
      border-radius: 5px 0 0 5px;
      width: 100%;
      height: 100px;
      object-fit: cover; } }

  .categoryName {
    padding: 5px;
    width: 65%;
    h4 {
      margin: 5px; }
    p {
      color: #aaaaaa;
      font-weight: 400;
      font-size: 14px;
      margin: 5px; } }

  .arrowIcon {
    align-self: center;
    width: 10px;
    margin-right: 10px;
    color: #aaaaaa;
    transform: rotate(180deg); } }


.burgerMenu.open {
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  //opacity: 1
  display: block;
  z-index: 900; }

.sideMenu {
  display: none; }


@media (min-width: 700px) {
  .burgerMenu, .burger {
    display: none; }

  .categoryName {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p, h4 {
      margin: 15px 5px;
      font-weight: normal; } }



  .categoriesContainer {
    display: none;
    .hoverElement {
      display: none; } } }
