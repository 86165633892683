
@import "../../style.module.sass";

.header {
  padding: 30px 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;


  img {
    max-width: 32px;
    margin-bottom: 7px; }

  .text {
    font-size: 20pt;
    font-weight: bold;
    margin: 15px 0 0; }

  .orderCreatedText {
    margin: 0;
    color: $textGrey;
    font-size: 11pt;
    font-weight: 300;
    text-align: left; } }


.menuItemBox {
  margin-bottom: 25px; }

.itemModifier {
  color: $textGrey;
  font-size: 11pt;

  .itemModifier::before {
    content: "- "; } }

.deliveryDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .value {
    margin: 0;
    font-weight: 500; } }

.title {
  font-weight: 400;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 0.2px; }

.itemDateSection {
  background: #f1f1f1;
  margin-bottom: 0;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px 5px;
  margin-left: -5px; }

.nameWithPhoto {
  display: flex;
  justify-content: space-between; }

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .itemTitle {
    font-weight: 600;
    margin: 0;
    letter-spacing: 0.2px; }

  .itemInfo {
    font-size: 11pt;
    margin: 3px 0 0; }

  .itemPrice {
    font-weight: 600;
    font-size: 13pt;
    margin: 0;
    min-width: 70px;
    text-align: right;

    .currency {
      display: inline-block;
      margin-left: 5px;
      font-weight: 400;
      font-size: 11pt; } } }

.menuItemBoxPhotoBlock {
  flex-grow: 0;
  margin-right: 10px;

  img {
    width: 90px !important;
    height: 60px;
    border-radius: 5px; } }


.buttonContainer {
  position: fixed;
  width: 100%;
  max-width: 500px;
  bottom: 0;
  padding: 10px 0;
  background: rgba(247, 247, 247, 0.1);
  backdrop-filter: blur(2px);
  z-index: 20;
  .button {
    background: $primaryPink;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    border-radius: 50px;
    padding: 15px 20px;
    margin: 0 30px; }
  .button.inverse {
    color: $primaryPink;
    background: #ffffff;
    text-align: center;
    font-weight: 600;
    border-radius: 50px;
    padding: 15px 20px;
    margin: 0 30px; } }

@media (min-width: 700px) {
  .paperBlock, .header {
    width: 61%;
    margin: auto; }
  .buttonContainer {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .button, .button.inverse {
      padding: 18px 110px; } } }

@media (min-width: 1200px) {
  .buttonContainer {
    max-width: 1700px; } }



