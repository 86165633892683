@import "../../style.module.sass";

.plusMinusWidget {
  display: flex;
  align-items: center;
  .counter {
    position: relative;
    top: 0px;
    display: inline-block;
    margin: 0 10px;
    font-size: 14pt; } }

.plusMinusWidget {
  .small {
    top: 1px; } }
