@import "../../../../style.module.sass";

.toCheckoutContainer {
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  z-index: 100;
  position: fixed;

  bottom: 0;
  padding: 10px 20px;

  .text {
    font-weight: 600; }

  .button {
    background: $primaryPink;
    border-radius: 50px;
    color: $white;
    width: 100%;
    padding: 10px 20px;
    text-align: center;
    box-sizing: border-box; } }


@media (min-width: 700px) {
  .toCheckoutContainer {
    max-width: 1200px;
    display: flex;
    justify-content: flex-end;
    .button {
      display: inline-block;
      width: 40%;
      margin-right: 15%; } } }

@media (min-width: 1200px) {

  .toCheckoutContainer {
    max-width: 1700px;
    .button {
      width: 30%;
      margin-right: 23%; } } }



