@import '../../style.module';

.errorBoundary {
  position: fixed;
  width: 100%;
  margin: auto;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background-color: $lightGrey;

  .errorBoundaryContainer {
    margin: 55% 10px; }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: #FF2C3D;
    text-align: center;
    font-weight: 600;
    border-radius: 10px;
    padding: 15px 20px;
    margin: 20px 10px;
    p {
      margin: 0 0 0 15px; } }

  .infoContainer {
    margin: 10px;
    display: flex;
    p {
      margin: 0; }
    p:first-of-type {
      margin-bottom: 10px; }
    h4 {
      margin: 5px 0 0 0; }
    div {
      margin: 0px 0 10px 12px; } }

  .paperBlock {
    background-color: rgb(243, 231, 230);
    border-radius: 10px;
    padding: 20px;
    margin: 0 10px; }


  .footer {
    width: 100%;
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    bottom: 10px; } }

@media (min-width: 700px) {
  .errorBoundary {
    .errorBoundaryContainer {
      width: 60%;
      margin: 25% auto; } } }

@media (min-width: 1100px) {
  .errorBoundary {
    .errorBoundaryContainer {
      margin: 15% auto; } } }

