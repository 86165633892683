@import '../../../../style.module';

.workingTimeInfo {
  position: fixed;
  max-width: 500px;
  box-sizing: border-box;
  margin: auto;
  padding: 10px;
  overflow: hidden;
  top: 0;
  bottom: 0;
  z-index: 101;
  background: linear-gradient(180deg, rgba(216, 216, 216, 0.26) 16.61%, #FFFFFF 69.25%);
  backdrop-filter: blur(1px);
  display: flex;
  flex-direction: column;
  justify-content: center;


  &.close {
    display: none; }

  .workingTimeContainer {
    margin: 0 10px; }

  .button {
    color: #ffffff;
    background: #FF2C3D;
    text-align: center;
    font-weight: 600;
    border-radius: 10px;
    padding: 15px 20px;
    margin: 20px 0; }

  .infoContainer {
    display: flex;
    background: white;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;

    p, h4 {
      margin: 0; }
    div {
      margin: 10px 0 10px 12px; } }

  .footer {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    bottom: 10px;
    position: absolute;
    left: calc((min(100vw, 500px) - 286px)/2);
    img {
      object-fit: cover;
      height: 30px; } }
  p {
    margin-bottom: 10px;
    margin-top: -8px;
    color: #9f9f9f;
    font-size: 11pt; } }

@media (min-width: 700px) {
  .infoContainer {
    justify-content: center;
    align-items: center; }
  .workingTimeInfo {
    max-width: 1700px;
    width: 100%;
    .workingTimeContainer {
      width: 90%;
      margin: 0 auto; }
    .footer {
      left: calc((min(100vw, 97%) - 286px)/2); } } }

@media (min-width: 900px) {
  .workingTimeInfo {
    .workingTimeContainer {
      width: 60%; } } }

@media (min-width: 1200px) {
  .workingTimeInfo {
    .workingTimeContainer {
      width: 50%; } } }

