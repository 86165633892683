@import '../../../../style.module';


h3:last-of-type {
  margin: 30px 0 0 0; }


.dateContainer {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.dateButton + .dateButton {
  margin-left: 10px; }

.dateButton {
  width: 10%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid $lightGrey;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.07);
  text-align: center;
  &:hover {
    cursor: pointer; }
  p {
    margin: 5px 0 0 0;
    font-size: 10pt; }
  p:first-of-type {
    font-size: 12pt;
    font-weight: bold; } }

.dateButton.checked {
  border: 1px solid $primaryPink; }

.timeContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  justify-items: center;
  margin: 20px 0;
  p {
    margin: 5px 0 0 0; } }


.slot {
  width: 80%;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 15px 20px;
  text-align: center;
  border: 1px solid $white;
  &:hover {
    cursor: pointer; } }


.slot.checked {
  border: 1px solid $primaryPink; }


.slot.disabled {
  background-color: $textGrey;
  border: 1px solid $textGrey;
  opacity: 50%;
  &:hover {
    cursor: auto; }
  p {
    color: #0A0A0A; } }



