@import "../../../../../style.module.sass";


.categoryContainer {
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: calc((100% - 10px) / 2) calc((100% - 10px) / 2);
  grid-column-gap: 10px; }

.categoryHeader {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
  padding-top: 20px; }


.menuItemContainer {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-bottom: 30px;
  background: white;
  position: relative;
  padding-bottom: 60px;
  z-index: 1;

  .tag {
    position: absolute;
    border-radius: 10px;
    font-size: 10pt;
    background: $primaryPink;
    font-weight: 600;
    color: white;
    padding: 2px 10px;
    margin: 0;
    top: 2px;
    left: 2px; }

  .content {
    padding: 5px;
    //border-radius: 10px 10px 0 0
    background-color: #fff;
    position: relative;
 }    //margin-top: -14px

  .description {
    padding: 5px 0 0;
    min-height: 50px;
    margin: 0;
    font-size: 11pt;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-lines: 3;
    max-height: 54px;
    box-sizing: content-box;
    overflow: hidden;
    /* (Number of lines you want visible) * (line-height) */
    line-height: 18px; }


  .priceHint {
    color: $textGrey;
    font-size: 10pt;
    text-align: center;
    margin: 0 0 2px; }

  .title {
    font-size: 12pt;
    font-weight: 600;
    margin: 3px 0;
    &:first-child {
      margin: 28px 0 3px 0; } }



  .imgContainer {
    border-radius: 5px 5px 0 0;
    overflow: hidden;

    .img {
      object-fit: cover;
      position: relative;
      max-height: 150px;
      min-width: 100%; } } }


.mr20 {
  margin-right: 20px; }


.plusMinusWidget {
  display: flex;
  .counter {
    position: relative;
    top: 7px;
    display: inline-block;
    margin: 0 10px;
    font-size: 14pt; } }

.supplierLogo {
  position: absolute;
  top: 6px;
  left: calc(100% - 55px);
  display: flex;
  flex-direction: row;
  z-index: 10; }

.newBadgeImg {
    position: absolute;
    top: 6px;
    left: 8px;
    display: flex;
    flex-direction: row;
    z-index: 10; }


.cookingTime {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
  background-color: $lightGrey;
  padding: 5px;
  border-radius: 15px;
  font-size: 10pt;
  img {
    margin-right: 3px;
    width: 17px; } }

@media (max-width: 420px) {
  .cookingTime {
    width: 50%; } }

@media (max-width: 406px) {
  .cookingTime {
    width: 55%; } }


.badgeWithTime {
  display: flex;
  justify-content: right;
  height: 26px;
  align-items: center; }

.withBadges {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.hitBadgeOverPhoto, .hitBadge {
  padding: 4px 10px;

  background: linear-gradient(90deg, rgba(255,112,65,1) 0%, rgba(255,215,71,1) 100%);
  border-radius: 15px;
  p {
    color: white;
    margin: 0;
    font-size: 9pt;
    font-weight: 500;
    line-height: 18px; } }

.newBadgeOverPhoto, .newBadge {
  padding: 4px 15px;

  margin-right: 10px;
  background: #49BC13;
  border-radius: 15px;
  p {
    color: white;
    margin: 0;
    font-size: 9pt;
    font-weight: 500;
    line-height: 18px; } }

.hitBadge {
  display: inline-block;
  margin-right: 5px; }


.newBadge {
 display: inline-block; }

.menuContainer {
  display: none; }

.badge {
  width: 30px; }
.newBadgeOverPhoto, .hitBadgeOverPhoto, .newBadge, .hitBadge {
  text-align: center; }

@media (min-width: 700px) {
  .categoryContainer {
    grid-template-columns: 48% 48%;
    justify-items: center; }

  .menuItemContainer {
    width: 90%; }

  .menuContainer {
    display: block;
    position: sticky;
    height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    top: 0;
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    margin: 30px 0px 5px 0;
    padding: 15px 5px;
    background: $lightGrey;
    box-sizing: border-box;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 10px 15px;
      .categoryName {
        font-size: 12pt; }
      .amountPositions {
        font-size: 10pt;
        font-weight: normal;
        color: #898989;
        text-align: right; } }

    .item.active {
      font-weight: bold;
      background-color: #FFFFFF;
      border: 1px solid $white;
      border-radius: 10px;
      box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.06);
      padding: 10px 15px;
      margin: 0; } } }






@media (min-width: 800px) {
  .badge {
    width: 35px; } }


@media (min-width: 900px) {
  .badge, .newBadgeOverPhoto, .hitBadgeOverPhoto, .hitBadge, .newBadge {
    width: 50px; }
  .badgeWithTime {
    height: 46px; } }

@media (min-width: 1000px) {
  .badge, .newBadgeOverPhoto, .hitBadgeOverPhoto, .hitBadge, .newBadge {
    width: 55px; }
  .menuContainer {
    margin-right: 10px; } }


@media (min-width: 1201px) {
  .categoryContainer {
    grid-template-columns: calc((100% - 10px) / 3) calc((100% - 10px) / 3 ) calc((100% - 10px) / 3); }
  .badge {
    width: 48px; } }

@media (min-width: 1400px) {
  .badge {
    width: 55px; } }
