@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }


@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }


@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }


@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }


.blinking {
  -webkit-animation: fadein 2s infinite alternate;
  -moz-animation: fadein 2s infinite alternate;
  -ms-animation: fadein 2s infinite alternate;
  -o-animation: fadein 2s infinite alternate;
  animation: fadein 1s infinite alternate; }
