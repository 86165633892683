@import "../../style.module.sass";

.mainContainer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  background: white;
  min-height: calc(100vh - 248px - 82px);
  .deliveryPayment, .content {
    width: 47%; }
  .content {
    margin-top: 140px; } }

.header {
  margin: auto; }

@media (min-width: 1200px) {
  .mainContainer {
    .content {
      margin-top: 218px; } } }

.backgroundContainer {
  margin: auto;
  background: white;
  min-height: 100vh;
  box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  right: 0;
  padding-bottom: 120px; }

.headerWrapper {
  margin: auto;
  background: white;
  box-sizing: border-box; }

@media (min-width: 700px) {
  .backgroundContainer,.headerWrapper {
    max-width: 1000px; } }

@media (min-width: 1201px) {
  .headerWrapper {
    padding: 0 10px; } }

.back {
  margin-top: 70px; }
@media (min-width: 1200px) {
  .back {
    margin-top: 150px; } }

.itemAmount {
  display: flex;
  flex-direction: column; }

.header {
  padding: 30px 10px 15px;
  background: white; }

.editTableButton {
  color: $orangeColor;
  font-size: 11pt;
  font-weight: 500; }

.button.inverse {
  color: $primaryPink;
  background: #ffffff;
  text-align: center;
  font-weight: 600;
  border-radius: 50px;
  padding: 15px 20px;
  margin: 0 30px; }


.deliverySettingsTitle {
  font-weight: 400;
  margin: 10px 0 0 0;
  letter-spacing: 0.2px;
  font-size: 12pt; }

.sectionTitle {
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: 16pt; }


.deliverySettingsAddressInput, .input {
  padding: 15px 10px;
  margin: 5px 0 0 0;
  min-width: 100%;
  box-sizing: border-box;
  border: solid 1px #bababa;
  outline: none;
  border-radius: 5px;
  appearance: none;
  box-shadow: none;
  font-size: 12pt; }


.deliverySettingsAddressInput:focus {
  border: solid 1px $primaryPink; }

.menuItemBox {
  margin-bottom: 15px;
  margin-top: 7px; }

.menuItemBoxPhotoBlock {
  flex-grow: 0;
  margin-right: 10px;

  img {
    width: 90px !important;
    height: 60px;
    border-radius: 5px; } }

.itemModifier {
  color: $textGrey;
  font-size: 11pt;
  margin: 3px 0 0 10px; }

.itemModifier::before {
  content: "- "; }

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .itemTitle, .itemDiscountTitle {
    font-weight: 500;
    margin: 0;
    letter-spacing: 0.2px; }

  .itemDiscountTitle {
    font-weight: normal;
    margin: 10px 0; }

  .itemInfo {
    font-size: 11pt;
    font-weight: 300;
    margin: 3px 0 0; }

  .itemPrice, .itemDiscountPrice {
    font-weight: 600;
    margin: 0;
    text-align: right;
    min-width: 70px;

    .currency {
      display: inline-block;
      margin-left: 5px;
      font-weight: normal; } } }

.delivery {
  font-size: 12pt;
  margin-top: 30px;
  span {
    font-weight: bold;
    font-size: 12pt; } }


.bonusTitle {
  font-weight: 500;
  letter-spacing: 0.2px;
  margin: 0 0 3px 0; }

.bonuses {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px; }

.bonusButton, .changeTime {
  padding: 10px 20px;
  border: 1px solid $primaryPink;
  border-radius: 50px;
  color: $primaryPink; }

.timeToPickUp {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 7px 0;
  align-items: center;
  .time {
    margin: 0;
    font-weight: bold; } }


.timepicker {
  input {
    border: 0px solid #d9d9d9;
    font-weight: bold;
    font-size: 12pt;
    padding: 4px 7px 4px 0; }
  i {
    &:after {
      display: none; } } }



.bonusCardSectionContainer {
  //min-height: calc(100vh - 60px - 60px)
  //max-height: calc(100vh - 60px - 60px)
 }  //overflow-y: scroll


.bonusCardSection {
  position: fixed;
  top: 500px;
  opacity: 0;
  z-index: 0;
  max-width: 480px;
  background-color: white;
  box-sizing: border-box;
  overflow-y: scroll;
  border-radius: 20px 20px 0 0;
  margin: auto;
  padding: 15px;
  left: 20px;
  right: 20px;
  transition: top 0.3s cubic-bezier(0,1.09,.48,1.0), left 0.3s cubic-bezier(.26, 1.14, .12, .94), right 0.3s cubic-bezier(.26, 1.14, .12, .94), opacity 0.2s; }

.open {
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  z-index: 900;

  .bonusTitle {
    width: 60%;
    line-height: 20px;
    margin-bottom: 20px;
    p:first-of-type {
      font-size: 17pt;
      font-weight: bold;
      letter-spacing: 0.3px;
      margin-bottom: 5px; } } }

.radioButtons {
  display: flex;
  flex-direction: column; }

.radioLabel {
  border: 1px solid #CACACA;
  border-radius: 10px;
  padding: 20px 15px;
  align-items: center;
  justify-content: center;


  svg {
    margin-right: 10px;
    fill: #CACACA; } }

.loyaltyType {
  position: absolute;
  opacity: 0;
  width: 0;
  &:checked + .radioLabel {
    border: 1px solid $primaryPink;
    font-weight: bold;
    svg {
      fill: $primaryPink; } } }


.infoBlock {
  font-size: 15px;
  background: #EBF4FF;
  color: rgba(0,0,0,0.75);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  align-items: flex-start;


  span {
    display: block; }

  img {
    display: block;
    margin-right: 15px; } }


.bonusButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 20px 10px;
  div {
    text-align: center; } }
.addButton {
  width: 55%;
  padding: 20px;
  background-color: $primaryPink;
  color: #FFFFFF;
  border-radius: 10px;
  font-weight: bold; }


.buttonContainer, .sbpButtonContainer {
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  background: rgba(247, 247, 247, 0.1);
  backdrop-filter: blur(2px);
  margin-top: 30px;

  .button, .sbpButton {
    background: $primaryPink;
    color: #ffffff;
    text-align: center;
    font-weight: 600;
    border-radius: 10px;
    padding: 18px 20px;
    margin: 0 5px; }

  .button.disabled {
    background: #d7d7d7;
    color: $textGrey;
    text-align: center;
    font-weight: 600;
    border-radius: 10px;
    padding: 18px 20px;
    margin: 0 5px; } }

.sbpButtonContainer {
  bottom: 65px;

  .sbpButton {
    background: rgb(21, 14, 51);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    min-height: 55px;
    box-sizing: border-box;
    p {
      margin: 0 10px 0 0; }
    img {
      height: 35px; } } }

.offertaBlock {
  font-size: 11pt;
  color: $textGrey;
  margin: 7px 10px;
  text-align: center;
  a {
    color: $orangeColor; } }



.radioButtonGroup {
  background: white;
  margin: 10px 5px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  display: flex; }


.radioInput {
  display: none; }

.radioInput + .radioLabel {
  cursor: pointer;
  padding: 15px;
  position: relative;
  border-radius: 5px;
  border: 1px solid #fff;
  width: 100%;
  box-sizing: border-box;

  svg {
    width: 40px;
    margin-right: 20px; }


  .deliveryType, .paymentType {
    p {
      margin: 0 0 3px 0; }

    p:first-child {
      font-weight: 400; }

    p:last-child {
      color: $textGrey; } } }


.radioInput:checked + .radioLabel {
  border: 1px solid $primaryPink;

  svg {
    path {
      fill: $primaryPink; } } }



.address, .failedAddress {
  display: flex;
  justify-content: space-between;
  position: relative;
  .container {
    min-width: 100%;
    z-index: 2; }
  p {
    margin: 24px 0 14px 0; } }

.failedAddress {
  .container {
    input {
      border: 1px solid $primaryPink; } } }


.loader {
  position: absolute;
  top: 17px;
  right: 0;
  z-index: 2; }

.nothingFound {
  box-sizing: border-box;
  position: absolute;
  min-width: 100%;
  top: 54px;
  right: 0;
  border: 1px solid $lightGrey;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  z-index: 3;
  p {
    color: $textGrey;
    font-size: 11pt;
    padding-left: 5px;
    text-align: center;
    margin: 25px 0; } }

.suggestion {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  p {
    margin: 2px 0;
    font-size: 12pt;
    font-weight: 400;
    padding: 11px 15px;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    background: rgba(0,0,0, 0.02); } }

.suggestionsContainer {
  padding: 0;
  box-sizing: border-box; }

.suggestionsList {
  top: 58px;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 2px;
  margin-top: 0;
  border: 1px solid $lightGrey;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box; }

.container {
  box-sizing: border-box;
  input {
    box-sizing: border-box;
    &:focus {
      box-sizing: border-box;
      border: 1px solid $primaryPink; } } }


.floorPorch {
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
  margin-top: 10px;

  .floor, .porch, .flat {
    display: flex;
    max-width: 30%;
    flex-direction: column;

    p {
      margin-top: 0;
      margin-bottom: 5px;
      font-weight: 400; }

    input {
      min-width: 20px;
      margin: 0; }

    input:focus {
      border: 1px solid $primaryPink; } } }


.takeawayToTime {
  width: 15% !important;
  margin-right: 0;
  position: relative;
  svg {
    &:last-of-type {
      width: 20px;
      position: absolute;
      top: 35px;
      left: 25px;
      background-color: white; } } }

.noDeliveryPrice, .deliveryPrice {
  width: 95%;
  margin-top: 30px;
  padding: 10px;
  border-radius: 10px;
  background-color: #FFE6E8;
  color: $dangerRed; }

.deliveryPrice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #E6F7E7;
  color: #058F13;
  p {
    margin: 0; } }

.header {
  position: relative;
  z-index: 50;

  .nameAndDescription {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 0 10px 15px 10px; }

  .text {
    font-size: 21px;
    font-weight: bold;
    margin: 0;
    color: #FFFFFF;
    line-height: 20px; }

  input {
    display: none;
    &:checked ~ .description {
      display: block; }
    & ~ .description {
      color: #FFFFFF;
      margin-top: 5px; } }

  .menuName {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .menuNameWithNoBackground {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0; }

  .backgroundPhoto {
    width: 100%;
    position: relative;

    img[alt='background'] {
      object-fit: cover;
      min-height: 360px;
      width: 100%;
      display: block; } }

  .backgroundPhotoShaddow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .4); } }

.infoDetailesSection, .isWaiterWantedSection, .isLangsModalSection, .isCartModal {
  position: fixed;
  top: 200px;
  //opacity: 0
  display: none;
  z-index: 0;
  max-width: 480px;
  background-color: white;
  box-sizing: border-box;
  overflow-y: scroll;
  border-radius: 20px 20px 0 0;
  margin: auto;
  left: 20px;
  right: 20px;
  transition: top 0.3s cubic-bezier(0,1.09,.48,1.0), left 0.3s cubic-bezier(.26, 1.14, .12, .94), right 0.3s cubic-bezier(.26, 1.14, .12, .94), opacity 0.2s;

  .nameAndDescription {
    padding: 10px 17px;
    margin-top: 10px;
    position: relative; }

  input {
    display: none;
    &:checked ~ .description {
      display: block; } }

  .menuName {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .logo {
    left: 50%;
    transform: translate(-50%, 0);
    top: 15px; } }

.description {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 0;

  .shadowContainer {
    padding: 10px 15px;
    border-radius: 15px;
    margin: 20px;
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.05); } }


.orderReminder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  border: 2px solid white;
  font-size: 12px; }


@media (max-width: 699px) {
  .header {
    .nameAndDescription {
      .menuName {}
      .logoTablet {
        display: none; } } } }


@media (min-width: 700px) {

  .header {
    z-index: auto;
    padding: 0 20px;
    .backgroundPhoto {
      height: 20vh;
      img[alt='background'] {
        position: absolute;
        bottom: 0;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px; } }
    .nameAndDescription {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      margin: 20px;
      padding: 20px;
      border-radius: 15px;
      bottom: -50%;
      z-index: 70;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, .08);
      .menuName {
        margin: 10px 10px 5px 10px;
        width: 100%;
        .logoTablet {
          width: 50px;
          margin-right: 20px;
          img {
            width: 100%;
            object-fit: cover;
            border-radius: 40px; }
          .orderReminder {
            width: 20px;
            top: 25px;
            left: 63px; } } }
      .text {
        color: #0a0a0a;
        font-size: 17pt;
        margin-bottom: 10px; }
      label {
        .description {
          color: #0a0a0a;
          font-size: 12pt; } } }

    .logo {
      display: none; }
    .logoName {
      display: flex;
      align-items: center; }
    .name {
      display: flex;
      flex-direction: column;
      justify-content: center; } } }

.backgroundPhoto, .backgroundPhotoShaddow {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; }


