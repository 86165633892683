@import "../../../../style.module.sass";


.catalogItem {
  margin-top: 20px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 3px;
  border-radius: 5px;
  padding-bottom: 10px;


  .chips {
    position: absolute;
    top: 5px;
    left: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }

  .backgroundImg {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
    position: relative; }

  .logoImage {
    height: 120px;
    width: 120px;
    border-radius: 60px;
    margin: -70px auto auto;
    position: relative; }

  .content {
    padding: 20px;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 24px;
      margin-top: 0;
      margin-bottom: 5px; }

    .description {
      font-size: 16px;
      margin: 0;
      line-height: 19px;
      padding: 0 20px;
      color: #0A0A0A;
      text-overflow: ellipsis;
      font-weight: 300; }

    .descriptionClosed {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      max-lines: 5;
      max-height: 94px;
      box-sizing: content-box;
      overflow: hidden; } } }

.buttonContainer {
  .button {
    background: $lightGrey;
    color: $primaryBlack;
    text-align: center;
    font-weight: 600;
    border-radius: 50px;
    padding: 10px;
    margin: 0 60px;
    font-size: 16px; } }
