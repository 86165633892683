@import "../../../style.module.sass";

.backButton {
  .img {
    max-width: 10px;
    margin-bottom: 0;
    margin-top: 0; }

  .title {
    color: #525151;
    font-size: 16px;

    position: relative;
    top: -4px;
    left: 8px; } }
