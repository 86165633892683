@import "../../../../../style.module.sass";


.categoryContainer {
  margin-bottom: 40px; }


.categoryHeader {
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
  padding-top: 20px; }


.menuItemContainer {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  margin-bottom: 15px;
  background: white;
  position: relative;
  z-index: 1;
  padding: 5px 10px 10px;

  .tag {
    position: absolute;
    border-radius: 10px;
    font-size: 10pt;
    background: $primaryPink;
    font-weight: 600;
    color: white;
    padding: 2px 10px;
    margin: 0;
    top: 2px;
    left: 2px; }

  .content {
    padding: 5px; }

  .description {
    padding: 5px 0 0;
    margin: 0;
    font-size: 11pt;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-lines: 3;
    max-height: 54px;
    margin-bottom: 5px;
    box-sizing: content-box;
    overflow: hidden;
    /* (Number of lines you want visible) * (line-height) */
    line-height: 18px; }


  .priceHint {
    color: $textGrey;
    font-size: 10pt;
    text-align: center;
    margin: 0 0 2px; }

  .title {
    font-size: 12pt;
    font-weight: 600;
    margin: 3px 0; }

  .imgContainer {
    border-radius: 5px 5px 0 0;
    overflow: hidden;

    .img {
      max-width: 100%;
      object-fit: contain; } } }


.mr20 {
  margin-right: 20px; }


.plusMinusWidget {
  display: flex;
  .counter {
    position: relative;
    top: 7px;
    display: inline-block;
    margin: 0 10px;
    font-size: 14pt; } }
