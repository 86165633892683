@import "../../../../style.module.sass";

.catalogItems {
  padding: 10px;
  position: relative;
  min-height: calc(100vh - 248px - 82px);
  background: $lightGrey; }

@media (min-width: 700px) {
  .catalogItems {
    display: grid;
    grid-template-columns: calc((100% - 16px) / 3) calc((100% - 16px) / 3) calc((100% - 16px) / 3);
    grid-column-gap: 10px;
    max-width: 850px;
    background: white;
    margin: auto; } }




