@import "../../style.module";

.text {
  font-size: 20pt;
  font-weight: bold;
  margin: 15px 0 0; }

.smallGreyText {
  margin: 0;
  color: $textGrey;
  font-size: 11pt;
  font-weight: 300; }
