
@import "../../style.module";

.container {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
  background: $lightGrey;


  .block {
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background: white;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin: -40px 10px 10px;

    img {
      height: auto;
      max-width: 100px;
      min-width: 90px;
      min-height: 90px;
      max-height: 100px; }

    .header {
      text-align: center;
      font-size: 16pt;
      font-weight: bold;
      margin-top: 40px; }

    .currency {
      margin-top: 40px;
      font-weight: 900;
      font-size: 16pt; }

    .text {
      font-size: 14pt;
      text-align: center;
      margin: 0;
      white-space: pre-line; }

    .requisites {
      background: #f6f6f6;
      padding: 20px 30px 20px 20px;
      border-radius: 10px;
      font-size: 15pt;
      font-weight: 700;
      margin-top: 40px;
      margin-bottom: 0;
      position: relative;

      img {
        transform: scale(0.3);
        display: inline-block;
        position: absolute;
        top: -20px;
        right: -20px; }

      span {
        display: inline-block;
        padding-right: 40px; } }



    .copiedHint {
      font-size: 11pt;
      margin-top: 5px;
      color: #239d1b; }

    .hidden {
      visibility: hidden; }

    .button {
      margin-top: 20px;
      background: $primaryPink;
      border-radius: 50px;
      color: $white;
      width: 100%;
      padding: 15px 20px;
      text-align: center;
      box-sizing: border-box;
      font-weight: 600; }

    .button.inverse {
      color: $primaryPink;
      background: #ffffff;
      text-align: center;
      font-weight: 600;
      border-radius: 50px;
      padding: 15px 20px;
      margin: 0 30px; } } }
