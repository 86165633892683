
@import "../../../style.module";

.container {
  display: flex;
  justify-content: space-around;
  height: 100vh;
  align-items: center;
  background: $lightGrey;


  .block {
    border-radius: 15px;
    padding: 80px 20px 20px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background: white;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin: -40px 40px 40px;

    img {
      height: auto;
      max-width: 100px;
      min-width: 90px;
      min-height: 90px;
      max-height: 100px; }

    .header {
      text-align: center;
      font-size: 16pt;
      font-weight: bold;
      margin-top: 40px; }

    .text {
      font-size: 14pt;
      text-align: center;
      margin: 0; }

    .button {
      margin-top: 60px;
      background: $primaryPink;
      border-radius: 50px;
      color: $white;
      width: 100%;
      padding: 15px 20px;
      text-align: center;
      box-sizing: border-box;
      font-weight: 600; } } }
