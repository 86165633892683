@import "../../style.module.sass";

.couponBlock, .activeCouponBlock, .failedCouponBlock {
  padding: 5px 10px;
  display: flex;
  align-items: center;

  h4 {
    margin: 0 0 0 0; }

  img {
    margin-right: 10px;
    width: 30px; } }


.couponBlock {

  img {
    margin-right: 10px;
    width: 22px; } }


.activeCouponBlock {
  justify-content: space-between; }

.activeCoupon {
  display: flex; }

.failedCouponBlock {
  p {
    margin: 0;
    color: $dangerRed;
    font-size: 11pt; } }


.couponInput, .activeCouponInput, .failedCouponInput, .checkingCouponInput {
  margin: 10px 0;
  padding: 15px 10px;
  min-width: 100%;
  box-sizing: border-box;
  border: solid 1px #bababa;
  outline: none;
  border-radius: 5px;
  appearance: none;
  box-shadow: none;
  font-size: 12pt; }


.couponInput:focus, .activeCouponInput:focus,  .checkingCouponInput:focus {
  border: 1px solid $primaryPink; }



.failedCouponInput {
  border-radius: $dangerRed; }



input::placeholder {
  opacity: 40%;
  font-size: 11pt; }


.removeCoupon {
  color: $dangerRed;
  font-size: 11pt; }

.inputWithLoader {
  position: relative; }
.loader {
  padding: 0;
  position: absolute;
  top: 17px;
  right: 0; }



