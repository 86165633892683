.closeButton {
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  background: rgba(0, 0, 0, .06);
  border-radius: 10px;
  width: 50%;
  padding: 10px 20px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer; }
