@import "../../../../style.module.sass";


.modalWindow {
  background: $white;
  position: fixed;
  //overflow-y: scroll
  z-index: 0;

  top: 0;
  left: 10px;
  right: 10px;
  transform: translateY(500px);
  transition: transform 0.3s cubic-bezier(0,1.09,.48,1.0), left 0.3s cubic-bezier(.26, 1.14, .12, .94), right 0.3s cubic-bezier(.26, 1.14, .12, .94), opacity 0.2s;
  opacity: 1;


  max-width: 520px;
  margin: auto;


  .img {
    max-width: 100%;
    min-width: 100%; }

  .container {
    padding: 10px;
    padding-top: 0;

    .title {
      font-weight: 600;
      font-size: 16pt;
      margin-bottom: 0;
      margin-top: 10px; }


    .description {
      margin-top: 10px;
      font-size: 12pt;
      white-space: pre-line; } } }


.modalWindow.open {
  //top: 0
  //bottom: 0
  //right: 0
  //left: 0
  opacity: 1;
  z-index: 900; }


.modifierSetTitle {
  color: $primaryBlack;
  font-size: 13pt;
  letter-spacing: 0.6px;
  font-weight: 600;
  margin-bottom: 0; }

.modifierSetChooseManyMaxCountHint {
  margin: 0;
  color: $textGrey;
  font-size: 14px; }

.modifierSetSpace {
  margin-bottom: 10px; }

.modifierSetBox {
  margin-top: 50px;

  .addPrice {
    color: $textGrey;
    display: inline-block;
    margin-left: 5px;
    font-size: 11pt; } }


.radioButtonGroup {
  background: white;
  margin: 10px 5px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08); }


.radioInput {
  display: none; }

.radioInput + .radioLabel {
  cursor: pointer;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #fff;

  svg {
    width: 40px;
    margin-right: 20px; }

  div {
    width: 85%; }

  .deliveryType, .paymentType {
    p {
      margin: 0 0 3px 0; }

    p:first-child {
      font-weight: 400; }

    p:last-child {
      color: $textGrey; } } }


.radioInput:checked + .radioLabel {
  border: 1px solid $primaryPink;

  svg {
    path {
      fill: $primaryPink; } } }


.radioInput:checked + .radioLabel:before {
  border: 1px solid $orangeColor;
  box-shadow: inset 0 0 0 4px #fff; }


.checkboxButtonGroup {
  padding: 15px 10px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 12px 6px rgba(0, 0, 0, 0.04); }


.checkboxInput {
  display: none; }

.checkboxLabel {
  font-size: 12pt;
  cursor: pointer;
  position: relative;
  font-weight: 500; }


.checkboxInput:checked + .checkboxLabel:before {
  border: 2px solid $orangeColor;
  box-shadow: inset 0 0 0 4px #fff;
  border-radius: 2px; }

.paySystems {
  display: flex;
  align-items: center;

  img {
    height: 35px;
    margin-top: 10px;
    margin-bottom: 3px;
    margin-right: 10px;
    /* margin-right: 15px; */
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px; } }


.chevronDown {
  position: fixed;
  bottom: 80px;
  right: 15px;

  img {
    width: 60px; } }



.modifierMsg {
  color: $primaryPink; }

.addToCartContainer {
  padding: 10px;

  max-width: 500px;
  margin: auto;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.08);

  .title {
    font-size: 14pt;
    font-weight: 600; }

  .price {
    font-weight: 600;
    font-size: 14pt;

    .currency {
      display: inline-block;
      margin-left: 5px;
      font-size: 11pt;
      font-weight: 400; } } }



.addToCartButtonContainer {
    flex-basis: 50%;
    flex-grow: 1; }

.plusMinusWidgetContainer {
  margin-right: 20px;
  display: flex;
  align-items: center;

  .counter {
    position: relative;
    display: inline-block;
    margin: 0 10px;
    font-size: 14pt; } }

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  p {
    font-weight: bold;
    margin-bottom: 3px; } }

@media (min-width: 700px) {
  .modalWindow {
    border-radius: 15px;
    .mainImg {
      height: 300px;
      width: 79%;
      display: table;
      margin: 35px auto;
      img {
        border-radius: 15px; } } }
  .modalWindow.open {
    border-radius: 15px; }
  .chevronDown {
    bottom: 10%; }

  .addToCartContainer {
    bottom: 1.5%; } }






