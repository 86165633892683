
.footer {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  //background-color: #F7F7F7
  img {
    object-fit: cover;
    height: 30px; }

  p {
    margin-bottom: 10px;
    margin-top: -8px;
    color: #9f9f9f;
    font-size: 11pt;
    text-align: center; } }



