.skFadingCircle {
  width: 18px;
  height: 18px;
  position: relative;
  margin: auto; }

.skCircle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #ffffff;
    border-radius: 100%;
    -webkit-animation: skCircleFadeDelay 1.2s infinite ease-in-out both;
    animation: skCircleFadeDelay 1.2s infinite ease-in-out both; } }

.skCircle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg); }

.skCircle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg); }

.skCircle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg); }

.skCircle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg); }

.skCircle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg); }

.skCircle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg); }

.skCircle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg); }

.skCircle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg); }

.skCircle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg); }

.skCircle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg); }

.skCircle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg); }

.skCircle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.skCircle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.skCircle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.skCircle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.skCircle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.skCircle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.skCircle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.skCircle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.skCircle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.skCircle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.skCircle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

@-webkit-keyframes skCircleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }

  40% {
    opacity: 1; } }

@keyframes skCircleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }

  40% {
    opacity: 1; } }
