$orangeColor: #FFA741;
$primaryPink: #FF2C3D;
$lightGrey: #F7F7F7;
$primaryBlack: #1D1D1D;
$white: #fff;
$textGrey: #9f9f9f;
$dangerRed: #DD1010;


body {
  font-family: 'Roboto', sans-serif; }
