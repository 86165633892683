@import "../../../../style.module.sass";

.header {
  position: relative;
  z-index: 50;


  .nameAndDescription {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    padding: 0 10px 15px 10px; }

  .text {
    font-size: 21px;
    font-weight: bold;
    margin: 0;
    color: #FFFFFF;
    line-height: 20px; }

  input {
    display: none;
    &:checked ~ .description {
      display: block; }
    & ~ .description {
      color: #FFFFFF;
      margin-top: 5px; } }

  .menuName {
    display: flex;
    justify-content: space-between;
    align-items: center; }


  .iconButtons {
    display: flex;
    justify-content: space-between;
    svg {
      width: 44px; }
    svg+svg {
      margin-left: 15px; } }

  .menuNameWithNoBackground {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0; }

  .backgroundPhoto {
    width: 100%;
    position: relative;

    img[alt='background'] {
      object-fit: cover;
      min-height: 360px;
      width: 100%;
      display: block; } }

  .backgroundPhotoShaddow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .4); }

  .logo {
    width: 80px;
    position: absolute;
    top: 20px;
    left: 15px;
    cursor: pointer;
    img {
      width: 100%;
      object-fit: contain;
      border-radius: 40px; } }

  .closeButton {
    width: 20px;
    position: absolute;
    top: 50px;
    right: 15px;
    img {
      width: 100%;
      object-fit: contain; } }

  .mt20 {
    margin-top: 20px; } }

.infoDetailesSection, .isWaiterWantedSection, .isLangsModalSection, .isCartModal {
  position: fixed;
  top: 200px;
  //opacity: 0
  display: none;
  z-index: 0;
  max-width: 480px;
  background-color: white;
  box-sizing: border-box;
  overflow-y: scroll;
  border-radius: 20px 20px 0 0;
  margin: auto;
  left: 20px;
  right: 20px;
  transition: top 0.3s cubic-bezier(0,1.09,.48,1.0), left 0.3s cubic-bezier(.26, 1.14, .12, .94), right 0.3s cubic-bezier(.26, 1.14, .12, .94), opacity 0.2s;
  .logo {
    width: 100px;
    height: 100px;
    position: fixed;
    top: -200px;
    left: -100vw;
    transition: top 0.5s cubic-bezier(.26,1.14,.12,.94), opacity 0.2s;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    border-radius: 50%;
    img {
      width: 100%;
      object-fit: contain;
      border-radius: 50%;
      z-index: 100; } }

  .isWaiterWantedSection {
    top: 500px; }
  a {
    text-decoration: none;
    color: #1a1a1a; }


  .address {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    p {
      margin: 0 0 0 10px; } }



  .schedule {
    display: flex;
    align-items: center;
    padding: 0 17px 10px;
    p {
      margin: 0 0 0 10px; }
    span {
      font-weight: bold;
      margin-right: 7px;
      display: inline-block;
      min-width: 25px;
      margin-top: -1px; } }

  .phoneNumber, .websiteIcon, .instagram {
    display: flex;
    align-items: flex-start;
    padding: 10px 0px;
    p {
      margin: 0 0 0 10px; }
    span {
      font-weight: bold;
      margin-right: 7px; } }

  .arrow {
    width: 8px;
    height: auto;
    transform: rotate(270deg);
    margin-top: -2px;
    cursor: pointer;
    transition: transform 0.2s; }

  .mondaySchedule {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .schedule {
      margin-left: 0px;
      display: flex;
      align-items: center;
      padding: 10px 0px;
      p {
        margin: 0 0 0 10px; }
      span {
        font-weight: bold;
        margin-right: 7px; } } }
  .legalInfo {
    padding: 10px 15px;
    border-radius: 15px;
    margin: 20px;
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.05);
    .infoItem {
      margin-top: 10px;
      font-size: 16px;
      a {
        color: $primaryPink;
        text-decoration: underline; } } }
  .sectionTitle {
    margin: 0;
    font-size: 17px;
    font-weight: 400;
    //font-variant: common-ligatures small-caps
    //text-transform: lowercase
    color: rgba(0, 0, 0, 0.4); } }



.showSchedule {
  background-color: #fff;
  .schedule {
    margin-left: 4px; }
  .mondaySchedule {
    margin-left: 0px; }
  .hidingPartOfSchedule {
    display: none; }
  transition: background-color 0.1s; }

.hideSchedule {
  padding-bottom: 10px;
  .hidingPartOfSchedule {
    display: block; }
  .arrow {
    transform: rotate(90deg); } }


.open, .openWaiterCall, .openLangsModal {
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  display: block;
  z-index: 900;



  .nameAndDescription {
    padding: 10px 17px;
    margin-top: 10px;
    position: relative; }


  input {
    display: none;
    &:checked ~ .description {
      display: block; } }

  .menuName {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .logo {
    left: 50%;
    transform: translate(-50%, 0);
    top: 15px; } }

.description {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0; }


.infoDetailsSectionContainer {
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-y: scroll;

  .shadowContainer {
    padding: 10px 15px;
    border-radius: 15px;
    margin: 20px;
    box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.05); } }


.waiter, .lang {
  padding: 20px 20px 0;

  p:last-of-type {
    font-weight: 300;
    font-size: 12pt;
    margin-top: 0; }
  p:first-of-type {
    font-weight: bold;
    font-size: 15pt;
    margin-bottom: 5px; } }

.detailsIcon {
  width: 22px;
  img {
    width: 100%;
    object-fit: contain; } }


.table {
  background-color: $lightGrey;
  padding: 20px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 12pt; }

.waiterButton {
  background: $primaryPink;
  color: #FFFFFF;
  font-size: 12pt;
  font-weight: bold;
  border-radius: 10px;
  padding: 15px 20px;
  margin-top: 20px;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer; }

.disabled {
  background: $lightGrey;
  color: $primaryBlack;
  padding: 0 20px;
  span {
    display: inline-block;
    margin: 15px 0; } }

.waiterCalled {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  text-align: center;
  width: 50%;
  left: 50%;
  transform: translate(50%, 0);

  p {
    font-size: 12pt;
    line-height: 12pt; } }

.buttonToMenu {
  width: 80%;
  border-radius: 10px;
  background-color: $lightGrey;
  padding: 15px 20px;
  font-size: 12pt;
  font-weight: bold; }

.orderReminder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  border: 2px solid white;
  font-size: 12px; }


.chosenLanguage {
  border-radius: 5px;
  border: 1px solid rgba(169, 169, 169, 0.45);
  background-color: white;
  padding: 10px 15px 10px 10px;
  font-size: 16px;
  p {
    display: flex;
    align-items: center;
    margin: 0 10px 0 0; } }

.chosen {
  border-color: $primaryPink; }

.langEmoji {
  font-size: 20px;
  display: inline-block;
  margin-right: 5px; }

.languages {
  background-color: white;
  border-radius: 5px;
  padding: 0px 15px 5px 10px;
  margin-top: 3px;
  font-size: 16px;

  .typeOfLanguage {
    p {
      display: flex;
      align-items: center;
      margin: 0; } } }


.openCartModal {
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 1;
  display: block;
  z-index: 900; }

@media (max-width: 699px) {
  .header {
    .nameAndDescription {
      .menuName {}
      .logoTablet {
        display: none; } }

    .iconButtonsDesktop {
      display: none; } } }

@media (min-width: 700px) {
  .header {
    z-index: auto;
    .backgroundPhoto {
      height: 20vh; }
    .nameAndDescription {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      margin: 20px;
      padding: 20px;
      border-radius: 15px;
      bottom: -33%;
      z-index: 70;
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, .08);
      .menuName {
        margin: 10px 10px 5px 10px;
        width: 100%;
        .logoTablet {
          width: 50px;
          margin-right: 20px;
          img {
            width: 100%;
            object-fit: cover;
            border-radius: 40px; }
          .orderReminder {
            width: 20px;
            top: 25px;
            left: 63px; } } }
      .iconButtons {
        svg {
          width: 50px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
          border-radius: 50%; } }
      .text {
        color: #0a0a0a;
        font-size: 17pt;
        margin-bottom: 10px; }
      label {
        .description {
          color: #0a0a0a;
          font-size: 12pt; } } }

    .logo {
      display: none; }
    .logoName {
      display: flex;
      align-items: center; }
    .name {
      display: flex;
      flex-direction: column;
      justify-content: center; } }

  .infoDetailesSection, .isWaiterWantedSection, .isLangsModalSection {
    max-width: 600px; }

  .dayTime {
    display: flex;
    align-items: center; } }

@media (min-width: 1201px) {
  .header {
    .iconButtonsDesktop {
      display: none; }
    .backgroundPhoto {
      height: 40vh;
      img[alt='background'] {
        position: absolute;
        bottom: 0;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px; } }
    .nameAndDescription {
      max-width: 70%;
      bottom: -24%;
      .menuName {
        .logoTablet {
          width: 80px;
          .orderReminder {
            width: 20px;
            top: 29px;
            left: 70px; } }
        .text, .description {
          margin-bottom: 3px; } } }

    .backgroundPhoto, .backgroundPhotoShaddow {
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px; }

    .iconButtons {
      display: none; } } }



@media (min-width: 1201px) {
  .header {
    .iconButtonsDesktop {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: -5%;
      right: 2%;
      z-index: 61;
      svg {
        width: 50px;
        border-radius: 50%;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2); }
      svg+svg {
        margin-left: 15px; } }

    .logoName {
      .name {
        .text {
          font-size: 15pt; } } }

    .nameAndDescription {
      .menuName {
        .logoTablet {
          .orderReminder {
            left: 85px; } } } } } }
















