@import "../../style.module";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  padding: 5px;
  height: 100vh;
  background: $lightGrey; }

.button, .buttonToCart {
  width: 100%;
  box-sizing: border-box;
  background: $primaryPink;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  padding: 15px 20px;
  margin: 0 30px; }

.buttonToCart {
  background: $lightGrey;
  color: $primaryBlack;
  font-weight: normal;
  margin-top: 10px; }

.title {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column; }
