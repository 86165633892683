.priceContainer {
  display: inline-block;
  position: relative;
  padding: 5px;
  .crossedContainer {
    padding-bottom: 0; }
  .price {
    display: inline-block;
    margin-right: 5px;
    font-weight: 600;
    font-size: 13pt; }

  .crossedPrice {
    display: inline-block;
    margin-right: 5px;
    font-weight: 600;
    font-size: 12pt;
    color: #777777; }

  .currency {
    font-size: 11pt; }

  .crossedCurrency {
    font-size: 10pt;
    color: #777777; }

  .crossedOverlay {
    position: absolute;
    top: 7px;
    left: 0;
    transform: rotate(12deg);
    transform-origin: left;
    -webkit-transform: rotate(12deg);
    -webkit-transform-origin: left;
    -ms-transform: rotate(12deg);
    -ms-transform-origin: left;
    border-top: 2px solid #F44336;
    width: 100%;
    height: 100%; } }
