
@import "../../../../style.module.sass";


.orderStatusContainer {
  display: flex;
  justify-content: center;


  img {
    max-width: 22px;
    margin-top: 10px; } }


.text {
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14pt; }

.orderCreatedText {
  margin: 0;
  font-size: 12pt;
  font-weight: 300;
  text-align: center;
  color: $textGrey; }

.orderStatus {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 10px; }

.statusIcon {
  max-width: 90px;
  width: 90px; }


.paperBlock {
  background: white;
  margin: 5px;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08); }

@media (min-width: 700px) {
  .paperBlock {
    width: 59%;
    margin: auto; } }
