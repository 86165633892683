@import "../../style.module.sass";


.header {
  padding: 30px 10px 15px;
  background: white;

  .text {
    font-size: 20px;
    font-weight: bold;
    margin: 15px 0 0; } }


.container {
  margin: auto;
  background: $lightGrey;
  min-height: 100vh;
  box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  right: 0;
  padding-bottom: 120px;

  .editTableButton {
    color: $orangeColor;
    font-size: 11pt;
    font-weight: 500; }

  .button.inverse {
    color: $primaryPink;
    background: #ffffff;
    text-align: center;
    font-weight: 600;
    border-radius: 50px;
    padding: 15px 20px;
    margin: 0 30px; }

  .paperBlock {
    background: white;
    margin: 5px;
    margin-top: 20px;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);

    .deliverySettingsTitle {
      font-weight: 400;
      margin: 0;
      margin-top: 10px;
      letter-spacing: 0.2px; }

    .deliverySettingsAddressInput {
      padding: 15px 10px;
      margin-top: 10px;
      min-width: 100%;
      box-sizing: border-box;
      border: solid 1px #bababa;
      outline: none;
      border-radius: 5px;
      appearance: none;
      box-shadow: none;
      font-size: 12pt; }

    .deliverySettingsAddressInput:focus {
      border: solid 1px $orangeColor; }

    .menuItemBox {
      margin-bottom: 25px; }

    .itemModifier {
      color: $textGrey;
      font-size: 11pt;
      margin: 3px 0 0 10px; }

    .itemModifier::before {
      content: "- "; }

    .item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .itemTitle {
        font-weight: 600;
        margin: 0;
        letter-spacing: 0.2px; }

      .itemInfo {
        font-size: 11pt;
        margin: 3px 0 0; }

      .itemPrice {
        font-weight: 600;
        font-size: 13pt;
        margin: 0;
        text-align: right;
        min-width: 70px;

        .currency {
          display: inline-block;
          margin-left: 5px;
          font-weight: 400;
          font-size: 11pt; } } } }

  .buttonContainer {
    position: fixed;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
    bottom: 0;
    padding: 10px 0;
    background: rgba(247, 247, 247, 0.1);
    backdrop-filter: blur(2px);

    .button {
      background: $primaryPink;
      color: #ffffff;
      text-align: center;
      font-weight: 600;
      border-radius: 50px;
      padding: 15px 20px;
      margin: 0 30px; }

    .button.disabled {
      background: #d7d7d7;
      color: $textGrey;
      text-align: center;
      font-weight: 600;
      border-radius: 50px;
      padding: 15px 20px;
      margin: 0 30px; } }

  .offertaBlock {
    font-size: 11pt;
    color: $textGrey;
    margin: 7px 10px;
    text-align: center;
    a {
      color: $orangeColor; } } }



.radioInput {
  display: none; }

.radioInput + .radioLabel {
  font-size: 12pt;
  cursor: pointer;
  padding-left: 28px;
  position: relative; }

.radioInput:checked + .radioLabel {
  font-weight: 500; }

.radioLabel:before {
  content: "";
  top: 1px;
  left: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  background: $orangeColor;
  border-radius: 50px;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 0 20px #E0E0E0; }

.radioInput:checked + .radioLabel:before {
  border: 1px solid $orangeColor;
  box-shadow: inset 0 0 0 4px #fff; }

