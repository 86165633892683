@import "../../../../style.module.sass";

.container {
  padding: 10px;
  position: relative;
  background: $lightGrey;
  min-height: 100vh;
  z-index: 60; }


@media (min-width: 700px) {
  .container {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 10px;
    padding: 75px 30px 20px 50px;
    background-color: #ffffff; }
  .burgerMenuCategoriesContainer {
    display: inline-block;
    margin-top: 30px; }

  .activeCategory {
    height: 40px;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 7px 0; }
  .activeCategory:hover {
    background-color: #ffffff;
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.06); }
  .activeCategory:hover {
    h4 {
      font-weight: bold; } }


  .background {
    position: relative;
    top: -185px;
    left: 5%;
    width: 90%;
    height: 40vh;
    z-index: -1;
    background-color: $lightGrey;
    border-radius: 10px;
    box-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.06); } }

@media (min-width: 950px) {
  padding: 75px 50px 20px 50px; }
