
@import "../../style.module";

.logo {
  position: relative;
  width: 90px;
  height: 57px;
  .container {
    background-color: #C2C2C2;
    border-radius: 36px;
    width: 90px;
    height: 57px;
    top: 0;
    position: absolute;
    transition: background-color 0.5s, opacity 0.5s, all 0.5s; }

  .circle {
    width: 45px;
    height: 45px;
    top: 6px;
    left: 6px;
    position: absolute;
    background: white;
    border-radius: 40px;
    transition: left 0.5s; }

  .imgLoader {
    margin-top: 5px;
    margin-left: 5px;
    width: 35px;
    -webkit-animation: spin 1s ease-in-out infinite;
    -moz-animation: spin 1s ease-in-out infinite;
    animation: spin 1s ease-in-out infinite;
    opacity: 1;
    transition: opacity 0.2s; } }


.logo.active {
  .container {
    background-color: #20C903;
    border-radius: 36px;
    width: 90px;
    height: 57px;
    top: 0;
    position: absolute; }
  .circle {
    left: 39px; }

  .imgLoader {
    opacity: 0; } }


.logo.hide {
  .container {
    transform: scale(5);
    opacity: 0; }

  .circle {
    left: 39px;
    transform: scale(5);
    opacity: 0; }

  .imgLoader {
    opacity: 0; } }





.block {
  height: 100vh;
  width: 100vw;
  max-width: 1700px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 1);
  transition: background-color 0.1s;
  margin: auto;
  overflow: auto;

  .text {
    margin-top: 10px;
    font-size: 14px;
    color: black;
    text-align: center;
    opacity: 1;
    transition: opacity 0.2s; } }

.block.hide {
  background-color: rgba(255, 255, 255, 0);
  .text {
    opacity: 0; } }



@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

