@import "../../style.module.sass";

.container {
  margin: auto;
  background: $lightGrey;
  min-height: 100vh;
  box-sizing: border-box;
  max-width: 500px;
  width: 100%;
  right: 0;
  padding-bottom: 120px; }

@media (min-width: 700px) and (max-width: 1200px) {
  .container {
    max-width: 1200px; } }

@media (min-width: 1201px) {
  .container {
    max-width: 1700px; } }


