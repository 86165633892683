
@import "../../style.module";

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $lightGrey;
  z-index: 200;
  overflow: hidden;


  .block {
    border-radius: 15px;
    padding: 20px 20px 20px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background: white;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    margin-left: 40px;
    margin-right: 40px;

    img {
      height: auto;
      max-width: 100px;
      min-width: 90px;
      min-height: 90px;
      max-height: 100px; }

    .header {
      text-align: center;
      font-size: 16pt;
      font-weight: bold;
      margin-top: 40px; }

    .text {
      font-size: 14pt;
      text-align: center;
      margin: 0; }

    .button {
      margin-top: 30px;
      background: $primaryPink;
      border-radius: 50px;
      color: $white;
      width: 100%;
      padding: 15px 20px;
      text-align: center;
      box-sizing: border-box;
      font-weight: 600; } } }

.inputLabel {
  font-weight: 400;
  margin: 0;
  margin-top: 10px;
  letter-spacing: 0.2px; }

.input {
  padding: 15px 10px;
  margin-top: 10px;
  min-width: 100%;
  box-sizing: border-box;
  border: solid 1px #bababa;
  outline: none;
  border-radius: 5px;
  appearance: none;
  box-shadow: none;
  font-size: 12pt; }

.input:focus {
  border: solid 1px $orangeColor; }
