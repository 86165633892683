.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[aria-hidden="true"]  { display: none; }


.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 44px;
  width: 100%;
  outline: none;
}

/*.slide {*/
/*  height: 300px;*/
/*}*/
.awssld__content {
  display: block;
  --slider-transition-duration: 10ms;
}

.awssld__bullets {
  bottom: 10px;
  z-index: 2;

  & button {
    width: 10px;
    height: 10px;
  }

  & .awssld__bullets--active {
    transform: scale(1.3);
  }
}

.awssldPreview {
  z-index: 1;
  position: relative;
  width: 100%;
  height: calc(50vw - 15px);
  max-height: 200px;
  @media (min-width: 700px) and (max-width: 895px) {
    height: calc(24vw - 15px);
  };
  @media (min-width: 896px) and (max-width: 1199px) {
    height: calc(24vw - 15px);
    max-height: 300px;
  };
  @media (min-width: 1200px) {
    height: calc(16vw - 15px);
    max-height: 277px;
  }
}

.awssldRegular {
  z-index: 1;
  position: relative;
  width: 100%;
  @media (min-width: 700px) and (max-width: 895px) {
    height: calc(24vw - 15px);
  };
  @media (min-width: 896px) and (max-width: 1199px) {
    height: 100%;
    max-height: 300px;
    border-radius: 15px;
    .awssld__wrapper {
      border-radius: 15px
    }
  };
  @media (min-width: 1200px) {
    height: 100%;
    max-height: 277px;
    border-radius: 15px;
    .awssld__wrapper {
      border-radius: 15px
    }
  }
}