@import "../../style.module.sass";


.header {
  padding: 30px 10px 15px;
  background: white; }

.orderListItem {
  display: flex;
  justify-content: space-between;

  .arrowIcon {
    align-self: center;
    width: 10px;
    margin-right: 10px;
    color: #aaaaaa;
    transform: rotate(180deg); }

  .statusLabel {
    display: flex;
    margin-top: 10px;
    div {
      margin-left: 10px; } }

  .deliveryDetailes {
    margin-top: 5pt;
    div {
      margin-top: 5pt; } }

  .orderId {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 16pt; }

  .deliveryType {
    font-weight: bold; }

  .newOrderIcon {
    color: #FFA741;
    line-height: 19px; }

  .cancelledOrderIcon {
    color: #FF2C3D;
    line-height: 19px; }

  .acceptedOrderIcon {
    color: #2db630;
    line-height: 19px; } }


